<template>
  <v-container fluid ma-0 pa-0>
    <Banner />
    <Feature />
    <Contact />
  </v-container>
</template>
<script>  
import Banner from '@/components/default/pages/Banner.vue'
import Feature from '@/components/default/pages/Feature.vue'
import Contact from '@/components/default/pages/contact/Contact.vue'

export default {
  components: {
    Banner,
    Feature,
    Contact
  }
}

</script>
