<template>
  <v-sheet>
    <v-container class="py-2">
      <v-responsive max-width="900" class="mx-auto">
        <v-container>
          <v-row no-gutters>
            <v-col 
              cols="12" 
              md="6" 
            >
              <v-form ref="form" v-model="valid" :disabled="sent" class="my-2">
                <v-text-field v-model="form.name" :rules="rule" label="Name" outlined></v-text-field>
                <v-text-field v-model="form.email" :rules="rule" label="Email" outlined></v-text-field>
                <v-text-field v-model="form.phone" :rules="rule" label="Phone" outlined></v-text-field>
                <v-text-field v-model="form.policy" label="Policy (if applicable)" outlined></v-text-field>
                <v-textarea
                  v-model="form.message"
                  :rules="rule"
                  label="Message"
                  outlined
                ></v-textarea>
                <v-btn
                  :disabled="sent"
                  :loading="loading"
                  submit
                  large
                  block
                  color="secondary"
                  min-width="180"
                  @click="sendContact"
                >Send</v-btn>
              </v-form>
              <v-alert
                v-if="errorMessage"
                dense
                outlined
                type="error"
              >
                {{ errorMessage }}
              </v-alert>
              <v-alert
                v-if="sent"
                dense
                text
                type="success"
              >
                Message sent
              </v-alert>
            </v-col>
            <v-col 
              cols="12" 
              md="6" 
              class="pl-5"
            >
              <div class="mt-2 text-md-body-1 font-weight-bold">Purchase Questions or Reservation Changes:&nbsp;</div>
              <div class="d-flex align-center mt-1">
                <v-icon>mdi-phone-outline</v-icon>
                <span class="ml-1">
                  <a href="#" class="text-decoration-none">{{ contactPhone }}</a>
                </span>
              </div>
              <div class="d-flex align-center mt-1 mb-3">
                <v-icon>mdi-email-outline</v-icon>
                <span class="ml-1 blue--text">
                  <a href="#" class="text-decoration-none blue--text">{{ contactEmail }}</a>
                </span>
              </div>
            
              <div class="mt-10 text-md-body-1 font-weight-bold">CLAIMS – START A CLAIM</div>
              <div class="mt-1">U.S.:&nbsp;<a class="text-decoration-none " href="tel:833-610-0736">(833) 610-0736</a></div>
              <div class="mt-0">Outside U.S./Canada: <a class="text-decoration-none" href="tel:843-494-5909">(843) 494-5909</a></div>
              <div class="mt-2 text-md-body-1 font-weight-bold">CLAIMS – EXISTING CLAIMS</div>
              <div class="mt-1">Claims hotline:&nbsp;<a class="text-decoration-none" href="tel:833-610-0736">833-610-0736</a>&nbsp;(or)&nbsp;<a class="text-decoration-none" href="tel:843-501-1064">843-501-1064</a></div>
              <div class="mt-0">Claims fax:&nbsp;<a class="text-decoration-none" href="tel:800-560-6340">800-560-6340</a></div>
              <div class="mt-2 text-md-body-1 font-weight-bold">CLAIMS/COVERAGE QUESTIONS</div>
              <div class="mt-1">Email: <a class="text-decoration-none blue--text" href="mailto:playtravel.nw@insurestays.com">playtravel.nw@insurestays.com</a></div>
        
            </v-col>
          </v-row> 
        </v-container>
        
      </v-responsive>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'
import { sendRequest } from '@/configs/requests'

export default {
    data: () => ({
      loading: false,
      sent: false,
      errorMessage: null,
      valid: true,
      form: {
        name: '',
        email: '',
        phone: '',
        policy: '',
        message: ''
      },
      rule: [(v) => !!v || 'Field required']
    }),
    computed: {
        ...mapState ({
            meta: (state) => state.microsite.meta
        }),
        contactPhone() {
            return this.meta.custom_phone
        },
        contactEmail() {
            return this.meta.custom_email
        }
    },
    methods: {
      async sendContact() {
        this.errorMessage = null

        if (this.valid) {
          this.loading = true
          try {
            await sendRequest('microsite/contact', 'POST', this.form)
            this.sent = true
            this.$refs.form.reset()
          } catch {
            this.errorMessage = 'Error sending message'
          }
          this.loading = false
        }
      }
    }
}
</script>
